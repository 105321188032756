<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('elective_pools_report')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('elective_pools_report')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :exportExcel="true"
                @exportExcel="getRowsExcel()"
            >
                <b-row>
                    <b-col sm="6" md="5" lg="3" xl="4">
                        <b-form-group :label="$t('course')">
                            <course-auto-complete
                                v-model="datatable.queryParams.filter.course_id"
                            ></course-auto-complete>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code">
                            </faculty-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="4">
                        <b-form-group :label="$t('department')">
                            <department-selectbox
                                v-model="datatable.queryParams.filter.department_code"
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                            ></department-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                v-model="datatable.queryParams.filter.program_code"
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                :department_code="datatable.queryParams.filter.department_code"
                            ></program-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="5" lg="3" xl="4">
                        <b-form-group :label="$t('slot_type')">
                            <slot-type-selectbox
                                v-model="datatable.queryParams.filter.type"
                                :multiple="true"
                            ></slot-type-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import ElectivePoolService from "@/services/ElectivePoolService";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox.vue";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox.vue";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";

import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import SlotTypeSelectbox from "@/components/interactive-fields/SlotTypeSelectbox";

export default {
    components: {
        SlotTypeSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        CourseAutoComplete,
    },
    metaInfo() {
        return {
            title: this.$t("elective_pools_report"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.toUpperCase("buttons"),
                        field: "buttons",
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase("id"),
                        field: "id",
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase("pool_type"),
                        field: "pool_type_name",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("faculty"),
                        field: "faculty_name",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("department"),
                        field: "department_name",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("program"),
                        field: "program_name",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("pool_name"),
                        field: "pool_name",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("course_code"),
                        field: "course_code",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("course_name"),
                        field: "course_name",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("date"),
                        field: "created_at",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("created_by"),
                        field: "created_by",
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter:{
                        course_id:null,
                        faculty_code:null,
                        department_code:null,
                        program_code:null,
                        type:null
                    },
                    page:1,
                    limit:20
                },
            },
        };
    },
    created() {
        this.filterSet();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {};
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.getRows();
        },

        changeColumn(key) {
            this.datatable.columns[key].hidden =
                this.datatable.columns[key].hidden == true ? false : true;
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return ElectivePoolService.report(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        onPageChange(params){
            this.datatable.queryParams.page = params.currentPage;
            this.getRows()
        },
        onPerPageChange(params){
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
        },
        getRowsExcel() {
            return ElectivePoolService.exportExcel()
                .then((response) => {
                    this._downloadFile(response, this.$t('elective_pools_title') + '.xlsx')
                })
                .catch(err => this.showErrors(err))
        }
    },
};
</script>

